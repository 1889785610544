import axios from 'axios';
import Vue from 'vue';
const token = localStorage.getItem('token')
const http = axios.create({
  // Replace with your desired base URL

  // baseURL: 'http://127.0.0.1:8000/api/v1/', 
  baseURL: 'https://warehouseapi.techcare-sy.com/api/v1/', 
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }
});


http.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);



const handleSuccessResponse = (response) => {

  const method = response.config.method;
  const status = response.status;

  if (method === "post" || method === "put" || method === "delete") {
    if (status === 200 || status === 201) {
      Vue.prototype.$toast.success("Success");
    } else if (status === 403) {
      window.location.href = '/login';
      Vue.prototype.$toast.error("Unauthorized");
    }else if (status === 422) {
      
      Vue.prototype.$toast.error(response.data.error);
    }
    
    else {
      Vue.prototype.$toast.error("Error");
    }
  }else{
    return response;
  }

};

// Error handler for responses
const handleErrorResponse = (error) => {
  if (error.response) {
    const status = error.response.status;
    const errorMessage = error.response.data.error || "Unexpected Error";

    if (status === 403) {
      Vue.prototype.$toast.error("Unauthorized");
      // window.location.href = '/login';
    } else if (status === 500) {
      Vue.prototype.$toast.error("Internal Server Error");
    } else {
      Vue.prototype.$toast.error(errorMessage);
    }
  } else {
    Vue.prototype.$toast.error("Unexpected Error");
  }

  return Promise.reject(error);
};

// Intercept responses
http.interceptors.response.use(
  handleSuccessResponse,
  handleErrorResponse
);
export default http;